import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PrivacyPolicyComponent } from '@modules/home/modules/profile/components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from '@modules/home/modules/profile/components/terms-of-use/terms-of-use.component';
import { CheckPasswordListComponent } from '@modules/identity/components/check-password/check-password-list.component';
import { CodeConfirmationComponent } from '@modules/identity/components/code-confirmation/code-confirmation.component';
import { SignInComponent } from '@modules/identity/components/sign-in/sign-in.component';
import { SignUpForm, SignUpModel, ValidationControl } from '@modules/identity/models/sign-up/sign-up.model';
import { TokenKind } from '@modules/identity/models/token-validation/token-validation.model';
import { IdentityService } from '@modules/identity/services/identity.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogWithTitleComponent } from '@shared/common/UI/dialog-with-title/dialog-with-title.component';
import { Language } from '@shared/models/language.model';
import { SharedModule } from '@shared/modules/shared.module';
import { InterfaceLanguageService } from '@shared/services/interface-language.service';
import { CommonValidators } from '@shared/validators/common.validators';
import { emailTakenValidator } from '@shared/validators/custom/email/email.async-validators';
import { GetValidationMessage } from '@shared/validators/validator.messages';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [SharedModule, CheckPasswordListComponent, DialogWithTitleComponent],
  selector: 'lt-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../../styles/identity-modal.scss', './sign-up.component.scss'],
})
export class SignUpComponent implements AfterViewInit, OnDestroy {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;

  nativeLanguages: Language[];

  showConfirmPassword = false;
  signUpForm: FormGroup<SignUpForm>;
  changeColor: boolean;

  getValidationMessage = GetValidationMessage;

  showErrorEmail = false;
  showErrorPassword = false;
  typingMode = true;

  destroy$ = new Subject<boolean>();
  emailInitStatus$ = new BehaviorSubject<boolean>(false);
  emailForRequest: string;

  validation = {
    hasMinlengthError: true,
    hasNonLatinError: true,
    hasNumberError: true,
    hasRegisterError: true,
  };

  constructor(
    private identityService: IdentityService,
    private translateService: TranslateService,
    private interfaceLanguageService: InterfaceLanguageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<SignUpComponent>,
    private cdr: ChangeDetectorRef,
    private fb: NonNullableFormBuilder
  ) {
    this.route.data.subscribe((data) => {
      this.nativeLanguages = data.nativeLanguages;
    });
    this.identityService.email$.pipe(take(1)).subscribe((email) => {
      this.emailForRequest = email;
    });

    this.signUpForm = this.fb.group<SignUpForm>({
      email: this.fb.control(this.emailForRequest, {
        validators: [Validators.required, ...CommonValidators.EmailValidatorGroup],
        asyncValidators: [emailTakenValidator.bind(this)],
      }),
      password: this.fb.control('', {
        validators: [Validators.required, ...CommonValidators.PasswordValidatorGroup],
      }),
      // nativeLanguageCode временный, в макете его больше нет, как на бэке уберут, тут тоже надо убрать
      nativeLanguageCode: this.fb.control(this.interfaceLanguageService.interfaceLanguage),
      interfaceLanguageCode: this.fb.control(this.interfaceLanguageService.interfaceLanguage),
    });

    // todo: подыскать варианты вместо skip - возможно заменить BehaviorSubject на ReplaceSubject
    this.signUpForm.controls.email.statusChanges.pipe(takeUntil(this.emailInitStatus$.pipe(skip(1)))).subscribe((status) => {
      if (status !== 'PENDING') {
        this.emailInitStatus$.next(true);
      }
    });
  }

  ngAfterViewInit() {
    this.emailInitStatus$.pipe(skip(1), take(1)).subscribe(() => {
      this.setInitialFocus();
      if (this.signUpForm.controls.email.invalid && this.signUpForm.controls.email.value !== '') {
        this.showErrorEmail = true;
        this.signUpForm.controls.email.markAsTouched();
      }
    });
    this.signUpForm.controls.email.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  setInitialFocus(): void {
    if (this.signUpForm.controls.email.invalid) {
      this.emailInput.nativeElement.focus();
    }
    if (this.signUpForm.controls.email.valid && this.signUpForm.controls.password.invalid) {
      this.passwordInput.nativeElement.focus();
    }
  }

  onSubmit(): void {
    if (this.signUpForm.valid) {
      this.identityService.saveEmail(this.emailInput.nativeElement.value);
      this.identityService.signUp(this.signUpForm.value as SignUpModel);
      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialog.open(CodeConfirmationComponent, {
          data: {
            headerName: 'IDENTITY.SIGN_UP.TITLE',
            confirmType: TokenKind.EmailConfirmation,
            email: this.signUpForm.controls.email.value,
            password: this.signUpForm.controls.password.value,
          },
          panelClass: 'dialog-with-title',
          autoFocus: true,
          disableClose: true,
        });
      });
    }

    this.checkPasswordErrors();
    this.typingMode = false;
    this.showErrorEmail = true;
    this.showErrorPassword = true;
  }

  onChangeColor(): void {
    this.changeColor = true;
  }

  onBlur(): void {
    this.changeColor = this.signUpForm.controls.password.value !== '';
    this.showErrorPassword = true;
    this.typingMode = false;
    this.checkPasswordErrors();
  }

  showPassword(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSignIn(): void {
    this.identityService.saveEmail(this.emailInput.nativeElement.value);
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialog.open(SignInComponent, {
        panelClass: 'dialog-with-title',
        autoFocus: true,
      });
    });
  }

  checkInputsFocus(): void {
    this.showErrorEmail = true;
    this.showErrorPassword = true;
    this.typingMode = false;
    if (this.signUpForm.controls.email.invalid) {
      this.emailInput.nativeElement.focus();
    } else if (this.signUpForm.controls.password.invalid) {
      this.passwordInput.nativeElement.focus();
    }
  }

  checkPasswordErrors(): void {
    const hasMinlengthError =
      this.signUpForm.controls.password.hasError('minlength') || this.signUpForm.controls.password.hasError('required');
    const hasNonLatinError = this.signUpForm.controls.password.hasError('nonLatin');
    const hasRegisterError =
      this.signUpForm.controls.password.hasError('oneUpper') || this.signUpForm.controls.password.hasError('oneLower');
    const hasNumberError = this.signUpForm.controls.password.hasError('oneNumber');

    this.validation = {
      hasMinlengthError,
      hasNonLatinError,
      hasNumberError,
      hasRegisterError,
    };
  }

  onPolicyOpen(): void {
    this.dialog.open(PrivacyPolicyComponent, {
      width: '900px',
      panelClass: 'dialog-with-title',
    });
  }

  onTermsOpen(): void {
    this.dialog.open(TermsOfUseComponent, {
      width: '900px',
      panelClass: 'dialog-with-title',
    });
  }

  // ! в процессе
  get controlColorEmail() {
    return this.signUpForm.controls.email.valid ? 'accent' : 'primary';
  }

  // ! в процессе
  get controlColorPassword() {
    return this.signUpForm.controls.password.valid ? 'accent' : 'primary';
  }

  backToTyping(event: KeyboardEvent, controlType: ValidationControl): void {
    if (controlType === 'email' && event.code !== 'Enter') {
      this.showErrorEmail = false;
      this.signUpForm.controls.email.markAsUntouched();
      this.signUpForm.controls.email.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.signUpForm.controls.email.hasError('taken')) {
          this.signUpForm.controls.email.markAsTouched();
          this.showErrorEmail = true;
        }
      });
    }

    if (controlType === 'password' && event.code !== 'Enter') {
      this.signUpForm.controls.password.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.checkPasswordErrors();
      });
      this.showErrorPassword = false;
      this.typingMode = true;
      this.signUpForm.controls.password.markAsUntouched();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.emailInitStatus$.complete();
  }
}
