import { Platform } from '@angular/cdk/platform';
import { AfterContentInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '@modules/identity/components/sign-in/sign-in.component';
import { SignUpComponent } from '@modules/identity/components/sign-up/sign-up.component';
import { SlideAnimation } from '@modules/identity/pages/landing/landing-types';
import { sliderAnimation } from '@modules/identity/pages/landing/slider.animation';
import { IdentityService } from '@modules/identity/services/identity.service';

@Component({
  selector: 'lt-landing',
  templateUrl: 'landing-page.component.html',
  styleUrls: ['landing-page.component.scss'],
  animations: [sliderAnimation],
})
export class LandingPageComponent implements AfterContentInit {
  currentSlide = 1;
  viewInit = true;

  termsOfUseLink = 'https://cdn.lingvotronic.com/docs/TermsOfUse.html';
  privacyPolicyLink = 'https://cdn.lingvotronic.com/docs/ConfidentialPolicy.html';

  currentYear = new Date().getFullYear();

  features = [
    {
      number: 1,
      srcImg: 'assets/images/landing/pictures/slides/player-slide.svg',
      altText: 'listen player',
      modifierClass: 'first',
      title: 'LANDING.SLIDER.FIRST_SLIDE.TITLE',
      description: 'LANDING.SLIDER.FIRST_SLIDE.DESCRIPTION',
    },
    {
      number: 2,
      srcImg: 'assets/images/landing/pictures/slides/cards-slide.svg',
      altText: 'learn with cards',
      modifierClass: 'second',
      title: 'LANDING.SLIDER.SECOND_SLIDE.TITLE',
      description: 'LANDING.SLIDER.SECOND_SLIDE.DESCRIPTION',
    },
    {
      number: 3,
      srcImg: 'assets/images/landing/pictures/slides/book-slide.svg',
      altText: 'read book everywhere',
      modifierClass: 'third',
      title: 'LANDING.SLIDER.THIRD_SLIDE.TITLE',
      description: 'LANDING.SLIDER.THIRD_SLIDE.DESCRIPTION',
    },
  ];

  constructor(private readonly identityService: IdentityService, private dialog: MatDialog, private platform: Platform) {}

  getState(slideNumber: number): SlideAnimation {
    if (this.viewInit) {
      if (slideNumber == this.currentSlide) {
        return 'visible';
      }

      if (slideNumber > this.currentSlide) {
        return 'invisible';
      }
    }

    if (slideNumber !== this.currentSlide && slideNumber < this.currentSlide) {
      return 'leftOut';
    } else if (slideNumber == this.currentSlide) {
      return 'visible';
    } else if (slideNumber !== this.currentSlide && slideNumber > this.currentSlide) {
      return 'rightOut';
    } else {
      return 'invisible';
    }
  }

  ngAfterContentInit() {
    this.viewInit = false;
  }

  onSignInOpen(): void {
    this.dialog.open(SignInComponent, {
      panelClass: 'dialog-with-title',
      autoFocus: true,
    });
  }

  onSignUpOpen(): void {
    this.dialog.open(SignUpComponent, {
      panelClass: 'dialog-with-title',
    });
  }

  changeCurrentSlide(slideNumber: number) {
    this.currentSlide = slideNumber;
  }

  redirectBasedOnPlatform() {
    if (this.platform.ANDROID) {
      window.location.href = 'https://play.google.com/store'; // URL в Google Play
    } else if (this.platform.IOS) {
      window.location.href = 'https://www.apple.com/app-store'; // URL в App Store
    } else {
      this.onSignInOpen();
    }
  }
}
