import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WithCredentialsInterceptor } from '@shared/interceptors/with-credentials.interceptor';
import { SignOutInterceptor } from '@shared/interceptors/sign-out.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: WithCredentialsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SignOutInterceptor,
    multi: true,
  },
];
