import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { IdentityService } from '@modules/identity/services/identity.service';
import { Role } from '@shared/enums/role.enum';
import { map } from 'rxjs';

export const canMatchAdminPath: CanMatchFn = (_route: Route, _segments: UrlSegment[]) => {
  const identityService = inject(IdentityService);
  const router = inject(Router);

  return identityService.getIdentity().pipe(
    map((identity) => {
      if (identity?.role == Role.Admin) {
        return true;
      } else {
        return router.parseUrl('/');
      }
    })
  );
};
