<lt-dialog-with-title [title]="'IDENTITY.RESET_PASSWORD.TITLE'">
  <form class="identity-form" (ngSubmit)="onSendReset()" [formGroup]="sendResetForm">
    <p class="identity-form__heading">{{ 'IDENTITY.RESET_PASSWORD.HEADING' | translate }}</p>

    <div class="identity-form__fields">
      <mat-form-field class="lt-textfield" subscriptSizing="fixed" floatLabel="auto" appearance="outline">
        <mat-label>{{ 'FEEDBACK.LABEL_INPUT' | translate }} </mat-label>

        <input
          class="identity-form__input"
          #emailInput
          cdkFocusInitial
          (blur)="onBlur()"
          autocomplete="email"
          formControlName="email"
          matInput
          type="email"
          (keyup)="backToTyping($event)"
        />

        <mat-error #emailErrors="ltErrors" ltErrors="email">
          <span *ngIf="emailErrors.errors">
            {{ getValidationMessage('username', emailErrors.errors) | translate }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>

    <button class="identity-form__btn" lt-button bgColor="accent" type="submit" data-cy="reset-password-next">
      {{ 'IDENTITY.RESET_PASSWORD.STEP.FIRST.NEXT' | translate }}
    </button>
  </form>
</lt-dialog-with-title>
