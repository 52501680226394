import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export function emailTakenValidator(abstractControl: AbstractControl): Observable<ValidationErrors | null> {
  if (this.currentCredentials) {
    if (abstractControl.value === this.currentCredentials.email) {
      return of(null);
    }
  }

  return this.identityService.emailTaken(abstractControl.value).pipe(
    map((response: { taken: boolean }) => {
      if (response.taken) {
        return response;
      }
      return null;
    })
  );
}

export function emailFound(abstractControl: AbstractControl): Observable<ValidationErrors | null> {
  return this.identityService.emailTaken(abstractControl.value).pipe(
    map((response: { taken: boolean }) => {
      if (response.taken) {
        return null;
      }
      return response;
    })
  );
}
