import { Component, Input, OnInit, ChangeDetectorRef, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { SlimLoadingBarService, SlimLoadingBarEvent, SlimLoadingBarEventType } from './slim-loading-bar.service';
import { isPresent } from './slim-loading-bar.utils';
import { LoadingBarService } from '@modules/navigation/components/loading-bar/loading-bar.service';

@Component({
  selector: 'lt-navigation-loading-bar',
  templateUrl: 'loading-bar.component.html',
  styleUrls: ['loading-bar.component.scss'],
})
export class LoadingBarComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() progress = '0';
  @Input() show = true;
  isTransition = 'none';
  router$: Subscription;

  constructor(
    public slimLoadingBarService: SlimLoadingBarService,
    private _elmRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private loadingBarService: LoadingBarService
  ) {
    this.router$ = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.loadingBarService.start();
      }
      if (event instanceof NavigationEnd) {
        this.loadingBarService.complete();
      }
    });
  }

  ngOnInit(): void {
    this.slimLoadingBarService.events.subscribe((event: SlimLoadingBarEvent) => {
      if (event.type === SlimLoadingBarEventType.PROGRESS && isPresent(event.value)) {
        this.progress = event.value;
        this.isTransition = event.value >= parseFloat(this.progress) ? 'all 0.5s ease-in-out' : 'none';
      } else if (event.type === SlimLoadingBarEventType.VISIBLE) {
        this.show = event.value;
      }
    });
  }

  ngAfterViewInit(): void {
    this.slimLoadingBarService.events.subscribe((event: SlimLoadingBarEvent) => {
      this._elmRef.nativeElement.visible = event.type === SlimLoadingBarEventType.VISIBLE ? event.value : true;
      this._changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.router$.unsubscribe();
  }
}
