import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { IdentityApi } from '@assets/api-urls';
import { Router } from '@angular/router';
import { IdentityStorage } from '@shared/services/identity.storage';
import { LoadingBarService } from '@modules/navigation/components/loading-bar/loading-bar.service';

@Injectable()
export class SignOutInterceptor implements HttpInterceptor {
  httpClient: HttpClient;

  constructor(
    private router: Router,
    private httpBackend: HttpBackend,
    private loadingBarService: LoadingBarService,
    private identityStorage: IdentityStorage
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.loadingBarService.start();

          this.httpClient
            .get(IdentityApi.GetIdentity)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                  this.httpClient
                    .get(IdentityApi.SignOut, {})
                    .pipe(
                      finalize(() => {
                        this.loadingBarService.stop();
                      })
                    )
                    .subscribe(() => {
                      this.identityStorage.identity = undefined;
                      this.router.navigateByUrl('/');
                    });
                }

                return throwError(err);
              })
            )
            .subscribe();
        }

        return throwError(error);
      })
    );
  }
}
