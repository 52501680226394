<lt-dialog-with-title [title]="'IDENTITY.SIGN_UP.TITLE'">
  <form class="identity-form" (ngSubmit)="onSubmit()" [formGroup]="signUpForm">
    <div class="identity-form__fields">
      <mat-form-field
        [class.mat-form-field-invalid]="showErrorEmail"
        [color]="controlColorEmail"
        floatLabel="auto"
        appearance="outline"
        class="lt-textfield"
        subscriptSizing="fixed"
      >
        <mat-label>{{ 'COMMON.INPUT.EMAIL' | translate }} </mat-label>
        <input
          #emailInput
          autocomplete="email"
          formControlName="email"
          matInput
          type="email"
          (keydown)="backToTyping($event, 'email')"
          (blur)="showErrorEmail = true"
        />
        <mat-error #emailErrors="ltErrors" ltErrors="email">
          <span *ngIf="emailErrors.errors && showErrorEmail">
            {{ getValidationMessage('email', emailErrors.errors) | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        [class.mat-form-field-invalid]="showErrorPassword"
        [color]="controlColorPassword"
        floatLabel="auto"
        appearance="outline"
        class="lt-textfield"
      >
        <mat-label>
          {{ 'COMMON.INPUT.PASSWORD' | translate }}
        </mat-label>
        <input
          #passwordInput
          (blur)="onBlur()"
          (focus)="onChangeColor()"
          formControlName="password"
          [type]="showConfirmPassword ? 'text' : 'password'"
          matInput
          type="password"
          (keydown)="backToTyping($event, 'password')"
        />

        <button
          type="button"
          matSuffix
          lt-button
          disabledHover
          disabledRipple
          aria-label="Toggle visibility"
          class="visibility-button"
          (mousedown)="showPassword($event)"
        >
          <mat-icon
            [ngClass]="{ 'visibility-button--dark': changeColor }"
            svgIcon="sign-in-visibility"
            class="visibility-button--light"
          ></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <lt-check-pass-list class="identity-form__check-pass" [isTyping]="typingMode" [validation]="validation"></lt-check-pass-list>

    <button class="identity-form__btn" color="accent" lt-button bgColor="accent" type="submit" data-cy="sign-up-btn">
      {{ 'IDENTITY.SIGN_UP.BUTTON.SIGN_UP' | translate }}
    </button>

    <div class="identity-form__terms-links">
      <span class="identity-form__text">
        {{ 'IDENTITY.SIGN_IN.CONTINUE' | translate }}
      </span>
      <a class="identity-form__link identity-form__link--blue" (click)="onPolicyOpen()">
        {{ 'IDENTITY.SIGN_IN.PRIVACY_POLICY' | translate }}
      </a>
      <span class="identity-form__text">
        {{ 'IDENTITY.SIGN_IN.AND' | translate }}
      </span>
      <a class="identity-form__link identity-form__link--blue" (click)="onTermsOpen()">
        {{ 'IDENTITY.SIGN_IN.TERMS_OF_USE' | translate }}</a
      >
    </div>

    <div class="identity-form__auth-link">
      <span class="identity-form__text">{{ 'IDENTITY.SIGN_UP.HAVE_ACCOUNT' | translate }} </span>
      <a class="identity-form__link identity-form__link--green" (click)="onSignIn()">
        {{ 'IDENTITY.SIGN_UP.BUTTON.SIGN_IN' | translate }}
      </a>
    </div>
  </form>
</lt-dialog-with-title>
