import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidators {
  static OneLower(): ValidatorFn {
    return function OneLowerValidatorFn(abstractControl: AbstractControl): ValidationErrors | null {
      const regexPattern = /[a-z]+/;

      if (!regexPattern.test(abstractControl.value)) {
        return { oneLower: true };
      }

      return null;
    };
  }

  static OneUpper(): ValidatorFn {
    return function OneUpperValidatorFn(abstractControl: AbstractControl): ValidationErrors | null {
      const regexPattern = /[A-Z]+/;

      if (!regexPattern.test(abstractControl.value)) {
        return { oneUpper: true };
      }

      return null;
    };
  }

  static NonLatin(): ValidatorFn {
    return function NonLatinValidatorFn(abstractControl: AbstractControl): ValidationErrors | null {
      const regexPattern = /\P{ASCII}+/u; // Detect non-ascii symbols

      if (abstractControl.value == '') {
        return { nonLatin: true };
      }

      if (regexPattern.test(abstractControl.value)) {
        return { nonLatin: true };
      }

      return null;
    };
  }

  static OneSpecial(): ValidatorFn {
    return function OneSpecialValidatorFn(abstractControl: AbstractControl): ValidationErrors | null {
      const regexPattern = /[!&$%? "]/;

      if (!regexPattern.test(abstractControl.value)) {
        return { oneSpecial: true };
      }

      return null;
    };
  }

  static OneNumber(): ValidatorFn {
    return function OneNumberValidatorFn(abstractControl: AbstractControl): ValidationErrors | null {
      const regexPattern = /[0-9]+/;

      if (!regexPattern.test(abstractControl.value)) {
        return { oneNumber: true };
      }

      return null;
    };
  }
}
