import { Injectable } from '@angular/core';
import { SlimLoadingBarService } from '@modules/navigation/components/loading-bar/slim-loading-bar.service';

@Injectable({ providedIn: 'root' })
export class LoadingBarService {
  constructor(private slimService: SlimLoadingBarService) {}

  set progress(newProgress: number) {
    this.slimService.progress = newProgress;
  }

  start(): void {
    this.slimService.start();
    this.slimService.progress = 30;
  }

  complete(): void {
    this.slimService.complete();
  }

  stop(): void {
    this.slimService.stop();
  }
}
