import { Directive, Input, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroupDirective, AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ErrorDetails, ErrorOptions, toArray } from '@shared/models/lt-errors.model';

// todo: посмотреть как можно оптимизировать

@Directive({
  selector: '[ltErrors]',
  exportAs: 'ltErrors',
})
export class LtErrorsDirective implements OnChanges, OnDestroy, AfterViewInit {
  @Input('ltErrors') controlName: string;

  errorsSubject$ = new BehaviorSubject<ErrorDetails | null>(null);

  control: AbstractControl;

  ready = false;

  constructor(private form: FormGroupDirective) {}

  get errors() {
    if (!this.ready) {
      return;
    }
    return this.control.errors;
  }

  get hasErrors() {
    return !!this.errors;
  }

  ngOnChanges() {
    this.control = this.form.control.get(this.controlName) as AbstractControl;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkStatus();
      this.control.statusChanges.subscribe(this.checkStatus.bind(this));
    });
  }

  hasError(name: string, conditions: ErrorOptions = ['dirty', 'touched']): boolean {
    return this.checkPropState('invalid', name, conditions);
  }

  isValid(name: string, conditions: ErrorOptions = ['dirty', 'touched']): boolean {
    return this.checkPropState('valid', name, conditions);
  }

  getError(name: string) {
    if (!this.ready) {
      return;
    }
    return this.control.getError(name);
  }

  private checkPropState(prop: string, name: string, conditions: ErrorOptions): boolean {
    if (!this.ready) {
      return false;
    }
    const controlPropsState = !conditions || toArray(conditions).every((condition: string) => this.control.get(condition));
    if (name.charAt(0) === '*') {
      return !!this.control.get(prop) && controlPropsState;
    }
    return prop === 'valid' ? !this.control.hasError(name) : this.control.hasError(name) && controlPropsState;
  }

  private checkStatus() {
    const control = this.control;
    const errors = control.errors;
    this.ready = true;
    if (!errors) {
      return;
    }
    for (const errorName in errors) {
      this.errorsSubject$.next({ control, errorName });
    }
  }

  ngOnDestroy() {
    this.errorsSubject$.complete();
  }
}
