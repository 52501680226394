<div class="dialog">
  <div class="dialog__title">
    <h2 class="mat-dialog-title dialog__title-text" data-cy="dialog-title">
      {{ title | translate }}
    </h2>

    <button lt-button class="dialog__btn" [mat-dialog-close]="null" data-cy="dialog-close">
      <mat-icon class="dialog__icon" svgIcon="close-icon"></mat-icon>
    </button>
  </div>
  <ng-content></ng-content>
</div>
