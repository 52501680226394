import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { CheckPasswordItemComponent } from '@modules/identity/components/check-password/check-password-item/check-password-item.component';
import { CheckPasswordListComponent } from '@modules/identity/components/check-password/check-password-list.component';
import { CodeConfirmationComponent } from '@modules/identity/components/code-confirmation/code-confirmation.component';
import { ResetPasswordComponent } from '@modules/identity/components/reset-password/reset-password.component';
import { SignInComponent } from '@modules/identity/components/sign-in/sign-in.component';
import { LandingPageComponent } from '@modules/identity/pages/landing/landing-page.component';
import { DialogWithTitleComponent } from '@shared/common/UI/dialog-with-title/dialog-with-title.component';
import { SharedModule } from '@shared/modules/shared.module';

@NgModule({
  declarations: [SignInComponent, ResetPasswordComponent, CodeConfirmationComponent, LandingPageComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    DialogWithTitleComponent,
    CheckPasswordListComponent,
    CheckPasswordItemComponent,
  ],
})
export class IdentityModule {}
