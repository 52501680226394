import { IdentityModule } from '@modules/identity/identity.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { httpInterceptorProviders } from '@shared/interceptors';
import { SentryProviders } from '@shared/senty/sentry.providers';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ToastComponent } from '@shared/common/UI/toast-notification/toast.component';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { LoadingBarModule } from '@modules/navigation/components/loading-bar/loading-bar.module';

export function HttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/i18n/');
}

registerLocaleData(localeRu);

// const gapiClientConfig: ClientConfig = {
//   client_id: environment.googleClientId,
//   scope: 'email profile openid',
// };

@NgModule({
  declarations: [AppComponent],
  imports: [
    IdentityModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
      progressAnimation: 'decreasing',
      maxOpened: 5,
      preventDuplicates: true,
      countDuplicates: true,
      toastComponent: ToastComponent,
    }),
    LoadingBarModule,
    AppRoutingModule,
  ],
  providers: [httpInterceptorProviders, SentryProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
