import { Injectable } from '@angular/core';
import { StatisticsService } from '@modules/statistics/services/statistics.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private titleSubject$ = new BehaviorSubject<string | undefined>('');
  public title$ = this.titleSubject$.asObservable();

  constructor(private translateService: TranslateService, private statisticsService: StatisticsService) {}

  public updateTitle(rawTitle: string, shouldTranslate = true) {
    let title: string;
    if (shouldTranslate) {
      title = this.translateService.instant(rawTitle);
      this.titleSubject$.next(title);
    } else {
      title = rawTitle;
      this.titleSubject$.next(title);
    }
    this.statisticsService.updateStatisticsTitle(title);
  }
}
