import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckPasswordItemComponent } from '@modules/identity/components/check-password/check-password-item/check-password-item.component';
import { PasswordValidationState } from '@modules/identity/components/check-password/password-validation.model';
import { SharedModule } from '@shared/modules/shared.module';

@Component({
  selector: 'lt-check-pass-list',
  templateUrl: 'check-password-list.component.html',
  styleUrls: ['check-password-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, CheckPasswordItemComponent],
})
export class CheckPasswordListComponent {
  @Input() isTyping: boolean;
  @Input() validation: PasswordValidationState;
}
