import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryEnable) {
  Sentry.init({
    dsn: 'https://bf646ba32d70b5c9d02b2ad1fc502eec@o4506417649811456.ingest.sentry.io/4506456036868096',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
  });
