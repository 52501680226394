import { CodeConfirmationComponent } from '../code-confirmation/code-confirmation.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { IdentityService } from '@modules/identity/services/identity.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { GetValidationMessage } from '@shared/validators/validator.messages';
import { CommonValidators } from '@shared/validators/common.validators';
import { of, throwError, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TokenKind } from '@modules/identity/models/token-validation/token-validation.model';
import { SendResetForm } from '@modules/identity/models/reset-password/reset-password.model';

@Component({
  selector: 'lt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../styles/identity-modal.scss', './reset.password.component.scss'],
})
export class ResetPasswordComponent {
  @ViewChild('emailInput') emailInput: ElementRef;

  email: string;
  emailForRequest: string | undefined;
  subscription: Subscription;
  sendForm = true;

  getValidationMessage = GetValidationMessage;

  sendResetForm = this.fb.group<SendResetForm>({
    email: this.fb.control('', {
      validators: [Validators.required, ...CommonValidators.EmailValidatorGroup],
      updateOn: 'submit',
    }),
  });

  constructor(
    private identityService: IdentityService,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private dialog: MatDialog,
    private fb: NonNullableFormBuilder
  ) {
    this.subscription = this.identityService.email$.subscribe((email) => (this.emailForRequest = email));

    if (this.emailForRequest !== undefined) {
      this.sendResetForm.controls.email.setValue(this.emailForRequest);
    }
  }

  // todo: переделать async валидацию и отправку форму в случае нескольких кликов
  onSendReset(): void {
    this.sendResetForm.controls.email.updateValueAndValidity();
    if (this.sendResetForm.valid && this.sendResetForm.controls.email.value !== '' && this.sendForm) {
      this.sendForm = false;
      this.identityService
        .sendPasswordReset(this.sendResetForm.controls.email.value)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              this.sendForm = true;
              this.sendResetForm.get('email')?.setErrors({ taken: true });
              return of(err);
            }
            return throwError(err);
          })
        )
        .subscribe(() => {
          if (this.sendResetForm.valid) {
            this.dialogRef.close();
            this.dialogRef.afterClosed().subscribe(() => {
              this.dialog.open(CodeConfirmationComponent, {
                data: {
                  confirmType: TokenKind.PasswordReset,
                  headerName: 'IDENTITY.RESET_PASSWORD.TITLE',
                  email: this.sendResetForm.controls.email.value,
                },
                panelClass: 'dialog-with-title',
                autoFocus: true,
              });
            });
          }
        });
    }
  }

  // timeout для постоянного фокуса в firefox
  onBlur(): void {
    setTimeout(() => {
      this.emailInput.nativeElement.focus();
    }, 0);
  }

  backToTyping(event: KeyboardEvent): void {
    if (event.code !== 'Enter') {
      this.sendResetForm.controls.email.setErrors(null);
    }
  }
}
