import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/modules/material.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorTranslated } from '@shared/intl/mat-paginator.intl';
import { FontfitDirective } from '@shared/directives/fontfit.directive';
import { FileUploaderComponent } from '@shared/components/file-uploader/file-uploader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipDirective } from '@shared/directives/tooltip/tooltip.directive';
import { TooltipComponent } from '@shared/common/UI/tooltip/tooltip.component';
import { PaginationComponent } from '@shared/common/UI/pagination/pagination.component';
import { AlreadyKnowModalComponent } from '@shared/common/UI/modals/already-know-modal/already-know-modal.component';
import { IconWithTitleComponent } from '@shared/common/UI/icon-with-title/icon-with-title.component';
import { ResetLearnModalComponent } from '@shared/common/UI/modals/reset-learn-modal/reset-learn-modal.component';
import { ToastComponent } from '@shared/common/UI/toast-notification/toast.component';
import { CustomTextareaComponent } from '@shared/common/UI/modals/bug-report-modal/report-word-field/report-word-field.component';
import { TextAreaFitDirective } from '@shared/directives/textarea-fit.directive';
import { FormatTimePipe } from '@shared/pipes/format-time.pipe';
import { CourseInfoComponent } from '@shared/common/UI/modals/course-info/course-info.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AlertDialogComponent } from '@shared/common/UI/modals/alert-dialog/alert-dialog.component';
import { UIModule } from 'src/app/UI/ui.module';
import { LtErrorsDirective } from '@shared/directives/lt-errors.directive';
import { LearnedFilterComponent } from '@shared/common/UI/modals/learned-filter-modal/learned-filter.component';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule, ReactiveFormsModule, FormsModule, OverlayModule, UIModule],
  declarations: [
    FileUploaderComponent,
    FontfitDirective,
    TextAreaFitDirective,
    TooltipDirective,
    TooltipComponent,
    PaginationComponent,
    AlreadyKnowModalComponent,
    CourseInfoComponent,
    ResetLearnModalComponent,
    IconWithTitleComponent,
    ToastComponent,
    CustomTextareaComponent,
    FormatTimePipe,
    AlertDialogComponent,
    LtErrorsDirective,
    LearnedFilterComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslateModule,
    FontfitDirective,
    TextAreaFitDirective,
    FileUploaderComponent,
    TooltipDirective,
    PaginationComponent,
    AlreadyKnowModalComponent,
    CourseInfoComponent,
    ResetLearnModalComponent,
    IconWithTitleComponent,
    ToastComponent,
    CustomTextareaComponent,
    FormatTimePipe,
    AlertDialogComponent,
    LtErrorsDirective,
    LearnedFilterComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => {
        const service = new MatPaginatorTranslated();
        service.injectTranslateService(translateService);
        return service;
      },
      deps: [TranslateService],
    },
  ],
})
export class SharedModule {}
