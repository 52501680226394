import { Component, Input } from '@angular/core';
import { SharedModule } from '@shared/modules/shared.module';

@Component({
  selector: 'lt-dialog-with-title',
  templateUrl: 'dialog-with-title.component.html',
  styleUrls: ['dialog-with-title.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class DialogWithTitleComponent {
  @Input() title: string;
}
