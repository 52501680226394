<lt-dialog-with-title [title]="'IDENTITY.SIGN_IN.TITLE'">
  <form class="identity-form" (ngSubmit)="onSubmit()" [formGroup]="signInForm">
    <div class="identity-form__fields">
      <mat-form-field appearance="outline" subscriptSizing="fixed" class="lt-textfield">
        <mat-label>{{ 'FEEDBACK.LABEL_INPUT' | translate }} </mat-label>
        <input
          (keyup)="backToTyping($event, 'login')"
          #emailInput
          cdkFocusInitial
          autocomplete="email"
          formControlName="login"
          matInput
          type="email"
        />

        <mat-error #loginErrors="ltErrors" ltErrors="login">
          <ng-container *ngIf="loginErrors.errors">
            {{ getValidationMessage('login', loginErrors.errors) | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="fixed" class="lt-textfield">
        <mat-label>{{ 'COMMON.INPUT.PASSWORD' | translate }} </mat-label>
        <input
          #passwordInput
          (blur)="onBlur()"
          (focus)="onChangeColor()"
          (keyup)="backToTyping($event, 'password')"
          formControlName="password"
          [type]="showConfirmPassword ? 'text' : 'password'"
          matInput
        />
        <button
          type="button"
          matSuffix
          lt-button
          disabledHover
          disabledRipple
          aria-label="Toggle visibility"
          class="visibility-button"
          (click)="showConfirmPassword = !showConfirmPassword"
        >
          <mat-icon
            [ngClass]="{ 'visibility-button--dark': changeColor }"
            svgIcon="sign-in-visibility"
            class="visibility-button--light"
          ></mat-icon>
        </button>

        <mat-error #passwordErrors="ltErrors" ltErrors="password">
          <ng-container *ngIf="passwordErrors.errors">
            {{ getValidationMessage('password', passwordErrors.errors) | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="identity-form__restore-password">
      <a class="identity-form__link identity-form__link--gray" (click)="onResetPassword()" data-cy="restore-password">
        {{ 'IDENTITY.SIGN_IN.BUTTON.FORGOT_PASSWORD' | translate }}
      </a>
    </div>

    <button class="identity-form__btn" lt-button bgColor="accent" type="submit" data-cy="sign-in-btn">
      {{ 'IDENTITY.SIGN_IN.BUTTON.SIGN_IN' | translate }}
    </button>

    <div class="identity-form__terms-links">
      <span class="identity-form__text">
        {{ 'IDENTITY.SIGN_IN.CONTINUE' | translate }}
      </span>
      <a class="identity-form__link identity-form__link--blue" (click)="onPolicyOpen()">
        {{ 'IDENTITY.SIGN_IN.PRIVACY_POLICY' | translate }}
      </a>
      <span class="identity-form__text">
        {{ 'IDENTITY.SIGN_IN.AND' | translate }}
      </span>
      <a class="identity-form__link identity-form__link--blue" (click)="onTermsOpen()">
        {{ 'IDENTITY.SIGN_IN.TERMS_OF_USE' | translate }}</a
      >
    </div>

    <div class="identity-form__auth-link">
      <span class="identity-form__text">{{ 'IDENTITY.SIGN_IN.DONT_HAVE_ACCOUNT' | translate }} </span>
      <a class="identity-form__link identity-form__link--green" (click)="onSignUp()">
        {{ 'IDENTITY.SIGN_IN.BUTTON.SIGN_UP' | translate }}
      </a>
    </div>
  </form>
</lt-dialog-with-title>
