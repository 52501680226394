import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

export const SentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];
