import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class InterfaceLanguageService {
  constructor(private translateService: TranslateService) {
    translateService.setDefaultLang('ru');
    translateService.use('ru');

    const localStorageLanguage = InterfaceLanguageService.loadLocalStorageLanguage();

    if (localStorageLanguage) {
      this.interfaceLanguage = localStorageLanguage;
    }
    // ToDo: Временно отказались от определения языка браузером, перереализовать по необходимости.
    // else {
    //   this.interfaceLanguage = translateService.getBrowserLang();
    // }
  }

  get interfaceLanguage(): string {
    return this.translateService.currentLang;
  }

  set interfaceLanguage(languageCode: string) {
    document.documentElement.lang = languageCode;
    this.translateService.use(languageCode);

    InterfaceLanguageService.setLocalStorageLanguage(languageCode);
  }

  private static loadLocalStorageLanguage(): string | undefined {
    const localStorageLanguage = localStorage.getItem('interfaceLanguage');

    if (localStorageLanguage !== null) {
      return JSON.parse(localStorageLanguage);
    } else {
      return undefined;
    }
  }

  private static setLocalStorageLanguage(interfaceLanguage: string): void {
    localStorage.setItem('interfaceLanguage', JSON.stringify(interfaceLanguage));
  }
}
