import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { IdentityService } from '@modules/identity/services/identity.service';
import { FormBuilder, Validators } from '@angular/forms';
import { GetValidationMessage } from '@shared/validators/validator.messages';
import { HttpErrorResponse } from '@angular/common/http';
import { TermsOfUseComponent } from '@modules/home/modules/profile/components/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from '@modules/home/modules/profile/components/privacy-policy/privacy-policy.component';
import { CommonValidators } from '@shared/validators/common.validators';
import { SignInForm, SignInModel } from '@modules/identity/models/sign-in/sign-in.model';
import { SignUpComponent } from '@modules/identity/components/sign-up/sign-up.component';
import { ResetPasswordComponent } from '@modules/identity/components/reset-password/reset-password.component';

@Component({
  selector: 'lt-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['../../styles/identity-modal.scss', './sign-in.component.scss'],
})
export class SignInComponent implements OnDestroy {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;

  showConfirmPassword = false;
  changeColor: boolean;
  subscription: Subscription;
  emailForReqest: string | undefined;

  signInForm = this.fb.group<SignInForm>({
    login: this.fb.control('', {
      validators: [Validators.required, ...CommonValidators.EmailValidatorGroup],
      updateOn: 'submit',
    }),
    password: this.fb.control('', { validators: [Validators.required], updateOn: 'submit' }),
  });

  getValidationMessage = GetValidationMessage;

  constructor(
    private identityService: IdentityService,
    private dialogRef: MatDialogRef<SignInComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.subscription = this.identityService.email$.subscribe((email) => (this.emailForReqest = email));
    if (this.emailForReqest !== undefined) {
      this.signInForm.controls.login.setValue(this.emailForReqest);
    }
  }

  onSubmit(): void {
    this.signInForm.controls.login.updateValueAndValidity();
    this.checkInputs();
    if (this.signInForm.valid) {
      this.identityService.signIn(this.signInForm.value as SignInModel).subscribe((errorResponse: HttpErrorResponse) => {
        if (errorResponse) {
          if (errorResponse.status === 404) {
            this.signInForm.get('login')?.setErrors({ notFound: true });
            this.emailInput.nativeElement.focus();
          }

          if (errorResponse.status === 403) {
            if (errorResponse.error.reason === 'EmailConfirmationRequired') {
              this.signInForm.get('login')?.setErrors({ notFound: true });
            } else {
              this.signInForm.get('password')?.setErrors({ wrong: true });
            }

            this.passwordInput.nativeElement.focus();
          }
        } else {
          this.dialogRef.close();
        }
      });
    }
  }

  onChangeColor(): void {
    this.changeColor = true;
  }

  onBlur(): void {
    this.changeColor = this.passwordInput.nativeElement.value !== '';
  }

  checkInputs(): void {
    if (this.signInForm.controls.password.value == '') {
      this.signInForm.controls.password.setErrors({ required: true });
    }
    if (this.signInForm.controls.login.errors !== null) {
      this.emailInput.nativeElement.focus();
    } else if (this.signInForm.controls.password.errors !== null) {
      this.passwordInput.nativeElement.focus();
    }
  }

  onSignUp(): void {
    this.identityService.saveEmail(this.emailInput.nativeElement.value);
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialog.open(SignUpComponent, {
        panelClass: 'dialog-with-title',
      });
    });
  }

  onResetPassword(): void {
    this.identityService.saveEmail(this.emailInput.nativeElement.value);
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialog.open(ResetPasswordComponent, {
        panelClass: 'dialog-with-title',
        autoFocus: true,
      });
    });
  }

  onPolicyOpen(): void {
    this.dialog.open(PrivacyPolicyComponent, {
      width: '900px',
      panelClass: 'dialog-with-title',
    });
  }

  onTermsOpen(): void {
    this.dialog.open(TermsOfUseComponent, {
      width: '900px',
      panelClass: 'dialog-with-title',
    });
  }

  backToTyping(event: KeyboardEvent, controlType: string): void {
    if (event.code !== 'Enter') {
      this.signInForm.controls[controlType].setErrors(null);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
