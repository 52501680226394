import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { LandingPageComponent } from '@modules/identity/pages/landing/landing-page.component';
import { CustomTitleStrategy } from '@modules/navigation/services/title-strategy';
import { canMatchHomePath } from '@shared/guards/home.guard';
import { canMatchRootPath } from '@shared/guards/identity.guard';
import { canMatchAdminPath } from '@shared/guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent,
    canMatch: [canMatchRootPath],
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canMatch: [canMatchHomePath],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canMatch: [canMatchAdminPath],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
