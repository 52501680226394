import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { IdentityService } from '@modules/identity/services/identity.service';
import { IdentityStorage } from '@shared/services/identity.storage';
import { map } from 'rxjs';

export const canMatchHomePath: CanMatchFn = (_route: Route, _segments: UrlSegment[]) => {
  const identityStorage = inject(IdentityStorage);
  const identityService = inject(IdentityService);
  const router = inject(Router);

  if (identityStorage.loggedIn && identityStorage.identity) {
    return true;
  } else {
    return identityService.checkLoggedIn().pipe(
      map((isLogged) => {
        if (!isLogged) {
          return router.parseUrl('/');
        }
        return true;
      })
    );
  }
};
